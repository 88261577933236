:root {
  /* colors */
  /* --text-primary: rgba(195, 219, 243, 0.8); */
  --text-primary: rgba(255, 255, 255, 1);
  --text-highlight: rgb(195, 219, 243, 1);
  --text-primary-faded: rgba(195, 219, 243, 0.4);
  --accent-primary: rgba(92, 168, 238, 1); /* #338BC5 : used for */
  /* --accent-primary: #2786ff; */
  --accent-lively: rgba(35, 126, 210, 1); /* #237ed2  */
  --accent-faded: rgba(
    92,
    168,
    238,
    0.5
  ); /* #338BC5 + fade: used for: light hr's and borders*/
  --accent-heavily-faded: rgba(
    92,
    168,
    238,
    0.15
  ); /* #338BC5 + fade: used for: light hr's and borders*/
  --link-text: rgba(255, 255, 255, 1);
  --link-hover: rgba(114, 199, 255, 1);
  --link-active: rgba(114, 199, 255, 1);
  --background-primary: rgba(0, 0, 0, 1);
  --background-primary-translucent: rgba(0, 0, 0, 0.9);
  --background-secondary: rgba(28, 28, 28, 1);
  /* --button-primary: rgba(228, 209, 54, 1);
  --button-primary-hover: rgba(253, 237, 113, 1);
  --button-primary-active: rgba(248, 244, 216, 1); */
  --button-primary: rgba(55, 100, 129, 1);
  --button-primary-hover: rgba(88, 128, 153, 1);
  --button-primary-active: rgba(248, 244, 216, 1);
  --stipe-color-gradient-one: rgba(9, 31, 52, 1);
  --stipe-color-gradient-two: rgba(6, 15, 23, 1);

  /* font weights */
  --thin: 100;
  --light: 300;
  --regular: 400;
  --strong: 600;

  /* spacing */
  --left-gutter: 4rem;
  --right-gutter: 4rem;
  --stripe-top-padding: 1rem;
  --stripe-bottom-padding: 1rem;
  --copy-indent: 2rem;

  /* text size */
  --xlarge: 2.5rem;
  --large: 1.5rem;
  --default: 1rem;
  --small: 0.75rem;
}

.dark-mode {
  /* --background-color: #050530; */
  --background-primary: #14145f;
  --text-primary: #e8ec70;
}

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

html {
  /* font-size:18px; */
}

body {
  margin: 0;
  font-family: 'Sarabun', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  background-color: var(--background-primary);
  color: var(--text-primary);
  font-weight: var(--thin);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: var(--text-primary);
  /* text-decoration-color: var(--accent-primary); */
  text-decoration-color: rgba(51, 139, 197, 1);
  text-decoration-thickness: 0.5px;
}

a:hover {
  color: var(--link-hover);
}

hr {
  background-color: var(--accent-primary);
  border-color: var(--accent-primary);
  width: 100%;
  text-align: left;
}

hr.faded {
  background-color: var(--accent-faded);
  border-color: var(--accent-faded);
}

hr.heavily_faded {
  background-color: var(--accent-heavily-faded);
  border-color: var(--accent-heavily-faded);
  /* background-color: pink;
  border-color: pink; */
}
hr.thin {
  background-color: var(--accent-primary);
  border-color: var(--accent-primary);
  width: 100%;
  height: 30%;
  text-align: left;
}

li {
  margin-left: 2rem;
  margin: 0.5rem 0rem 0.2rem 2rem;
  list-style: none;
}

ul li::before {
  content: '\2022';
  color: var(--accent-primary);
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

h1 {
  color: var(--accent-primary);
  font-weight: 200;
  font-size: 3rem;
  text-align: center;
}

h2 {
  color: var(--accent-primary);
  font-weight: 300;
  font-size: 1.5rem;
  text-align: center;
}

@media print {
  body {
    background-color: black !important;
    color: white !important; /* Ensures text is readable on black background */
  }
}
