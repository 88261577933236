:root {
  /* colors */
  --text-primary: rgba(195, 219, 243, 0.8);
  --text-highlight: rgb(195, 219, 243, 1);
  --text-primary-faded: rgba(195, 219, 243, 0.4);
  --accent-primary: rgba(51, 139, 197, 1); /* #338BC5 : used for */
  --accent-faded: rgba(
    51,
    139,
    197,
    0.5
  ); /* #338BC5 + fade: used for: light hr's and borders*/
  --accent-heavily-faded: rgba(
    51,
    139,
    197,
    0.25
  ); /* #338BC5 + fade: used for: light hr's and borders*/
  --link-text: rgba(255, 255, 255, 1);
  --link-hover: rgba(114, 199, 255, 1);
  --link-active: rgba(114, 199, 255, 1);
  --button-primary: rgba(228, 209, 54, 1);
  --button-primary-hover: rgba(253, 237, 113, 1);
  --button-primary-active: rgba(248, 244, 216, 1);

  /* font weights */
  --thin: 200;
  --regular: 400;
  --strong: 600;
}

a:link {
  color: var(--text-primary);
  text-decoration-thickness: 0.5px;
  text-decoration-color: var(--accent-primary);
}

a:link:hover {
  color: var(--link-hover);
}

body {
  color: var(--text-primary);
  font-weight: var(--thin);
}

hr {
  /* background-color: var(--accent-primary);
  border-color: var(--accent-primary); */
  /* background-color: red;
  border-color: red; */
  width: 100%;
  text-align: left;
}

hr.faded {
  background-color: var(--accent-faded);
  border-color: var(--accent-faded);
}

li {
  margin-left: 2rem;
  margin: 0.2rem 0rem 0.2rem 2rem;
}

h1 {
  color: var(--accent-primary);
  font-weight: 200;
  font-size: 3rem;
  text-align: center;
}

h2 {
  color: var(--accent-primary);
  font-weight: 300;
  font-size: 1.5rem;
  text-align: center;
}
