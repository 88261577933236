.footer{
    margin-top: 1rem;
    border-top: 1px solid var(--accent-primary);
    width: 100%;
    width: 100%;
    /* min-width: 100rem; */
    text-align: left;
}

.footer-links{
    display: flex;
    font-size: 1rem;
    list-style: none;
    justify-content: center;
    /* margin: 0.5rem 0rem; */
    margin: 1rem;
}

@media only screen and (max-width: 1100px) { 
    .footer-links{
        display: grid;
        grid-template-columns: auto;
        font-size: 1rem;
        list-style: none;
        justify-items: center;
        margin: 1rem;
    }
    
    .footer-links li{
        margin: 0rem 1rem;
    }
}

.product-link {
    border-bottom: 2px solid var(--accent-primary);
    }
