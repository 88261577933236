.donut-chart-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1200px; /* Increased size to account for glow */
  height: 300px; /* Increased size to account for glow */
  margin: 0 auto;
  overflow: visible; /* Ensure nothing gets clipped */
}

.background-image {
  position: relative;
  top: 0px; /* Move it down a bit if needed */
  left: -13px; /* Move it further to the left */
  width: 45%;
  height: 40%;
  z-index: 0;
  object-fit: scale-down;
}
.donut-chart-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1; /* Ensure the chart is above the image */
  width: 240px; /* Reduced to make space for the glow */
  height: 240px; /* Reduced to make space for the glow */
  display: flex;
  /* justify-content: center;
  align-items: center; */
  border-radius: 50%;
  box-shadow: 0px 0px 3px 4px rgba(89, 170, 229, 0.6);
  /* box-shadow: 0px 0px 20px 10px rgba(0, 123, 255, 0.3); */
  overflow: visible; /* Ensure the glow is not cropped */
}

.donut-chart-container svg {
  transform: rotate(-90deg);
  overflow: visible; /* Ensure SVG glow is not cropped */
}

.donut-chart-text {
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 7rem;
  font-weight: 500;
  color: white;
}

/* Optional glow for SVG */
.donut-chart-container svg {
  filter: drop-shadow(
    0 0 3px rgba(89, 170, 229, 0.3)
      /* filter: drop-shadow(
        0 0 3px rgba(93, 171, 255, 0.3) */
  ); /* Ensure glow isn't cut off */
}
