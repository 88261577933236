.header {
  width: 100%;
}

.fixed-header {
  border-top: 2px solid var(--accent-lively);
  overflow: hidden;
  background-color: var(--background-primary-translucent);
  position: fixed;
  top: 0px;
  width: 100%;
  border-bottom: 1px solid var(--accent-heavily-faded);
  margin: auto;
}
.header-content {
  display: flex;
  /* flex-wrap: wrap; */
  margin-top: 5px;
  justify-content: space-between;
}
.bottom_justified {
  margin-top: 2.1rem;
}

/* @media only screen and (max-width: 600px) { 
    .header-links{
        display: grid;
        grid-template-columns: auto;
        font-size: 1rem;
        list-style: none;
        justify-items: center;
    }
} */

.fixed-header-buffer {
  margin-top: 72px;
}

.logo {
  justify-self: start;
  /* min-width: 145px; */
  width: 102px;
  margin: 0rem 1rem;
}

.button-inverted {
  justify-self: flex-end;
  background-color: var(--background-primary);
  color: var(--accent-primary);
  border: 1px solid var(--accent-primary);
  border-radius: 0.5rem;
  text-align: center;
  display: inline-block;
  margin: 1rem 1rem 1rem 1rem;
  padding: 0.2rem 1rem 0.3rem 1rem;
  cursor: pointer;
  font-size: 0.7rem;
  font-weight: var(--light);
}

.button-inverted:hover {
  color: var(--text-highlight);
  border: 1px solid var(--text-highlight);
}
