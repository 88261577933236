.about-stripe {
  width: 100%;
}

.stripe.color-stripe {
  width: 100%;
  min-height: 580px;
  color: var(--text-primary);
}

.about-stripe-container {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 var(--right-gutter) 0 var(--left-gutter);
}

.about-stripe-contents {
  width: 100%;
}

.about-section-header {
  font-size: var(--xlarge);
  margin-bottom: 1.75rem;
  margin-top: 1rem;
  color: var(--accent-primary);
}

.about-stripe-header {
  font-size: var(--large);
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}

.about-stripe-copy {
  margin-bottom: 2rem;
  font-size: var(--default);
}

.team-image {
  width: 200px;
  height: 200px;
  margin-left: 1rem;
  margin-right: 1rem;
}

.team-member-block {
  /* margin: 1rem; */
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.about-top-copy {
  /* text-align: left; */
  max-width: 30rem;
  align-self: flex-start;
}

.about-plus-team-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* justify-content: space-around; */
  /* margin: 3rem 0rem; */
  width: 100%;
  /* display: flex;
  align-items: center; */
}

.linkedIn-icon {
  margin: 0.3rem;
  width: 1.5rem;
}

.icon_outer_container {
  /* border: 1px solid white; */
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
}

.icon_LinkedIn_container {
  width: 25px;
  /* height: 28px; */
}

.icon_row_container {
  display: flex;
  /* border: 1px solid rgb(0, 255, 187); */
}

.linkedIn_icon {
  margin-top: 4px;
  width: 25px;
  height: 25px;
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease, width 0.3s ease, height 0.3s ease;
  border: 1px solid black;
  border-radius: 3px;
  cursor: pointer;
}

.icon {
  width: 25px;
  height: 25px;
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease, width 0.3s ease, height 0.3s ease;
  border: 1px solid black;
  border-radius: 3px;
}
