.button-image {
  display: grid;
  align-items: center;
}

.product-image {
  border-radius: 5px;
  margin-right: 1px;
  margin-left: 8px;
  height: calc(100% - 2px);
  align-self: center;
}

.button-text {
  padding: 5px 5px 5px 10px;
  align-self: center;
}

.button-lead-in {
  padding: 0px;
  display: grid;
  grid-template-columns: 1fr auto;
}

.flex-box-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
}

.flex-box-item {
  width: 30rem;
  margin: 10px;
  padding: 0 0.5rem 0.5rem 0.5rem;
  border: 0.5px solid var(--accent-color-faded);
}

.flex-box-item1 {
  min-height: 100px;
}

.flex-box-item2 {
  min-height: 100px;
}

.sdevice-section {
  display: block;
  max-width: 30rem;
  /* min-width: 50rem; */
  margin: 0 auto;
}

.sound-button {
  cursor: pointer;
  margin-left: 1rem;
}

.inset {
  margin-left: 1rem;
}

.privacy-statement {
  margin-top: 1rem;
  font-size: var(--small);
}

.submit-button {
  border: none;
  /* padding: 0rem 0.3rem 0rem 0.3rem; */
  background-color: var(--accent-lively);
  border-radius: 5px;
  margin: auto;
  font-weight: var(--light);
  font-size: var(--deafult);
  cursor: pointer;
  width: 7rem;
  color: var(--text-primary);
}

.register-form {
  margin: 1rem 0rem;
  display: grid;
  grid-template-columns: auto auto;
}

.field-label {
  font-size: var(--small);
}

.boxed {
  margin-top: 1rem;
  border: solid 1px var(--accent-faded);
  padding: 1rem;
  border-radius: 0.5rem;
}

.citation {
  font-size: xx-small;
  color: var(--accent-primary);
}

.citation_name {
  font-size: small;
  color: var(--accent-primary);
}

.citation_link {
  color: var(--accent-primary);
}
