.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header-primary {
  /* color: red; */
  color: var(--accent-primary);
  font-size: var(--xlarge);
  margin-bottom: 1.75rem;
  margin-top: 5rem;
}

.App-link {
  color: var(--link-text);
  font-weight: 200;
  margin: 0.5rem 0.5rem;
  cursor: pointer;
  text-decoration: none;
}

.App-link:hover {
  color: var(--link-hover);
}

.App-link:active {
  color: var(--link-active);
  font-weight: 400;
}

.page {
  text-align: left;
  width: 100%;
}

.page-title {
  /* font-size: 2rem; */
  width: fit-content;
  display: block;
  margin: 0px auto;
  color: var(--text-primary);
  /* color: blue; */
  font-weight: var(--thin);
  /* font-style: italic; */
  padding-top: 0.5rem;
  font-size: var(--xlarge);
  margin-bottom: 1.75rem;
  margin-top: 5rem;
}

.section-header {
  font-size: var(--xlarge);
  margin-bottom: 1.75rem;
  margin-top: 1rem;
  color: var(--accent-primary);
}

.highlight {
  color: var(--text-primary);
  font-weight: 200;
}

.section-title {
  color: var(--accent-primary);
  font-weight: 300;
  font-style: italic;
  padding-top: 0.5rem;
}

.section-list {
  font-weight: 200;
}

.learn-more-link {
  list-style: none;
  font-style: italic;
}

.button {
  border: none;
  padding: 0.5rem 1rem;
  background-color: var(--button-primary);
  border-radius: 5px;
  margin: 1rem;
  font-weight: 300;
  font-size: 1rem;
  cursor: pointer;
}

.button:hover {
  background-color: var(--button-primary-hover);
}

.button:active {
  background-color: var(--button-primary-active);
}

.emphasis {
  font-style: italic;
}

.bold {
  font-weight: var(--strong);
}

.web-citation {
  margin-left: 5rem;
}

.warning {
  color: rgb(215, 177, 41);
}

.thin-weight {
  font-weight: var(--thin);
}

.medium-weight {
  font-weight: var(--regular);
}

.strong-weight {
  font-weight: var(--strong);
}

.info-block-container {
  width: 80%;
  background-color: var(--background-secondary);
  border-radius: 10px;
  margin: 1rem auto;
  padding: 1rem;
}

.info-block-header {
  font-weight: var(--regular);
  margin-bottom: 0.5rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.icons {
  cursor: pointer;
}

.image {
  margin: 0.5rem 1rem;
}

.caption {
  margin: 0.5rem 1rem;
  font-size: var(--default);
  color: var(--text-primary);
}

.icon-container {
  /* background-color: red; */
  min-width: 300px;
  margin-top: 4rem;
}

.icon-image {
  margin: auto;
  text-align: center;
}

.icon-image.side-icon {
  margin-bottom: calc(0.5rem - 18px);
}

.icon-caption {
  margin: 0.5rem 1rem;
  font-size: var(--default);
  color: var(--text-primary);
  text-align: center;
}

.empty-page {
  margin: 270px 0px;
}

.announcement {
  margin: 1rem 1rem 1rem 1.2rem;
  color: var(--accent-primary);
}

.announcement li {
  margin: 0rem 0rem 0rem 1rem;
  /* color: red; */
  list-style-type: circle;
}

.gutters {
  /* color: red; */
  /* margin: 5rem 5rem; */
  width: 92%;
}

.accent-color {
  color: var(--accent-primary);
}

.accent-border {
  border: 0.5px solid var(--accent-primary);
}

@media print {
  body {
    background-color: black !important;
    color: white !important; /* Ensures text is readable on black background */
  }
}
