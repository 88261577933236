.accordion {
  /* max-width: 400px; */
  /* box-shadow: 0 0 10px rgba(255, 255, 255, 0.2); */
  /* border-radius: 5px;  */
  overflow: hidden;
  /* background: #009578; */
}

/* .accordion__label{
    padding: 0.5rem 2rem;
} */

/* .accordion__content {
    margin-left: 2rem;
} */

.accordion__label {
  display: block;
  /* padding-left: 30px; */
  padding: 0.5rem 1.5rem;
  /* color: var(--accent-primary); */
  color: var(--accent-primary);
  font-weight: var(--thin);
  cursor: pointer;
  position: relative;
  transition: background 0.1s;
  /* margin-top: 0.5rem; */
}

.accordion__label:hover {
  /* background: rgba(51, 139, 197,0.5); */
  /* border: 1px solid var(--accent-primary); */
  /* text-decoration: underline; */
  /* color: var(--text-highlight); */
  color: var(--text-primary);
}

.accordion__label::after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  /* left: 10px; */
  left: 0px;
  width: 12px;
  height: 6px;
  background-image: 
    /* url('data:image/svg+xml;utf8,<svg width="100" height="50" xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 100,0 50,50" style="fill:%23338BC5;" /></svg>'); */
    url('data:image/svg+xml;utf8,<svg width="100" height="50" xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 100,0 50,50" style="fill:%23338BC5;" /></svg>');
  background-size: contain;
  transition: transform 0.4s;
}

.accordion__content {
  /* background: grey; */
  /* line-height: 1.6; */
  display: none;
}

.accordion__input {
  display: none;
}

.accordion__input:checked ~ .accordion__content {
  display: block;
}

.accordion__input:checked ~ .accordion__label::after {
  transform: translateY(-50%) rotate(-0.25turn);
}

.article-title {
}

.article-citation {
  font-size: 0.8rem;
  font-style: italic;
  margin-left: 2rem;
}

.article-summary {
}

.article-source {
}

.padded-text {
  padding: 20px 10px;
}

.flex-box-container-OSA {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-around; */
  /* align-items: center; */
  min-width: 200px;
}

.flex-box-item-OSA-text {
  width: 30rem;
  min-width: 100px;
}

.flex-box-item-imageOSA {
  margin: 0 auto;
  min-width: 100px;
}

.flex-box-item-imageOSA-title {
  display: block;
  width: 20rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.FAQ-article-citation {
  color: var(--accent-primary);
  font-size: 0.8rem;
  font-style: italic;
}

.FAQ-subheader {
  color: var(--accent-primary);
  font-size: var(--large);
  font-weight: var(--regular);
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}

.FAQ-flex-box-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
}

.FAQ-flex-box-illustration {
  width: 10rem;
  /* margin: 10px; */
  /* padding: 0 0.5rem 0.5rem 0.5rem; */
  border: 0.5px solid var(--accent-color-faded);
}
.FAQ-flex-box-caption-top {
  width: 15rem;
  /* padding: 0 0.5rem 0.5rem 0.5rem; */
  border: 0.5px solid var(--accent-color-faded);
  margin-top: 1rem;
  margin-bottom: auto;
}

.FAQ-flex-box-caption-bottom {
  width: 15rem;
  /* margin: 10px; */
  /* padding: 0 0.5rem 0.5rem 0.5rem; */
  border: 0.5px solid var(--accent-color-faded);
  margin-bottom: 1rem;
  margin-top: auto;
}
.illustrations-container {
  padding: 0px;
  display: grid;
  grid-template-columns: 1fr auto;
}

.FAQ-hosptial-logo {
  width: 20%;
  height: 20%;
  margin: 0.5rem;
}

.FAQ-light-text {
  font-weight: 100;
  /* color: red;  */
}

.FAQ-table {
  /* color: green; */
  /* margin: 5rem 20rem;
  border-color: none; */
  padding: 10px;
  margin: 0px 20px;
}

.FAQ-image {
  padding: 10px;
  margin: 0px 20px;
  max-width: 30rem;
}

.FAQ-health-issues {
  width: 40%;
  height: 40%;
}

.FAQ-flex-box-caption-health-issues {
  max-width: 40rem;
  /* margin: 10px; */
  /* padding: 0 0.5rem 0.5rem 0.5rem; */
  border: 0.5px solid var(--accent-color-faded);
  margin-bottom: 1rem;
  margin-top: auto;
}

.FAQ-connection {
  color: purple;
}
