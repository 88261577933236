a {
  color: var(--text-primary);
  /* text-decoration-color: var(--accent-primary); */
  text-decoration-color: rgba(51, 139, 197, 1);
  text-decoration-thickness: 0.5px;
}

a:hover {
  color: var(--link-hover);
}

.button-image {
  display: grid;
  align-items: center;
}

.product-image {
  border-radius: 5px;
  margin-right: 1px;
  margin-left: 8px;
  height: calc(100% - 2px);
  align-self: center;
}

.button-text {
  padding: 5px 5px 5px 10px;
  align-self: center;
}

.button-lead-in {
  padding: 0px;
  display: grid;
  grid-template-columns: 1fr auto;
}

.flex-box-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
}

.flex-box-item {
  width: 30rem;
  margin: 10px;
  padding: 0 0.5rem 0.5rem 0.5rem;
  border: 0.5px solid var(--accent-color-faded);
}

.flex-box-margin-only {
  margin: 10px;
}

.flex-box-item-wide {
  width: 46rem;
  margin: 10px;
  padding: 0 0.5rem 0.5rem 0.5rem;
  border: 0.5px solid var(--accent-color-faded);
}

.flex-box-item1 {
  min-height: 100px;
}

.flex-box-item2 {
  min-height: 100px;
}

.indent {
  margin-left: 1.5rem;
}

.sdevice-section {
  display: block;
  max-width: 30rem;
  /* min-width: 50rem; */
  margin: 0 auto;
}

.sound-button {
  cursor: pointer;
  margin-left: 1rem;
}

.inset {
  margin-left: 1rem;
}

.privacy-statement {
  margin-top: 1rem;
  font-size: var(--small);
}

.submit-button {
  border: none;
  /* padding: 0rem 0.3rem 0rem 0.3rem; */
  background-color: var(--accent-lively);
  border-radius: 5px;
  margin: auto;
  font-weight: var(--light);
  font-size: var(--deafult);
  cursor: pointer;
  width: 7rem;
  color: var(--text-primary);
}

.register-form {
  margin: 1rem 0rem;
  display: grid;
  grid-template-columns: auto auto;
}

.field-label {
  font-size: var(--small);
}

.boxed {
  margin-top: 1rem;
  border: solid 1px var(--accent-faded);
  padding: 1rem;
  border-radius: 0.5rem;
}

.boxed-small {
  margin-top: 1rem;
  border: solid 1px var(--accent-faded);
  padding: 1rem;
  border-radius: 0.5rem;
  width: 190px;
}

.citation {
  position: relative;
  top: -0.3rem;
  /* left: 10px; */
  /* line-height: 0; */
  font-size: x-small;
  color: var(--accent-primary);
}

.citation_name {
  font-size: small;
  color: var(--accent-primary);
}

.citation_link {
  color: var(--accent-primary);
}

.accent_primary {
  color: var(--accent-primary);
  font-size: larger;
  font-weight: 300;
}

.accent_primary_color_only {
  color: var(--accent-primary);
}

.accent_primary_no_color {
  font-size: larger;
  font-weight: 300;
}

.accent_standard {
  color: var(--accent-primary);
  /* font-weight: var(--thin); */
  /* font-size: larger;
    font-weight: 300; */
}

.page-secondary-title {
  /* font-size: 2rem; */
  width: fit-content;
  display: block;
  margin: 0px auto;
  color: var(--text-primary);
  /* color: blue; */
  font-weight: var(--thin);
  /* font-style: italic; */
  padding-top: 0.5rem;

  font-size: var(--large);
  margin-bottom: 1.75rem;
  margin-top: 1rem;
}

.App-link-color {
  /* color: var(--link-active); */
  color: var(--accent-primary);
  font-weight: 200;
  /* margin: 0.5rem 0.5rem; */
  cursor: pointer;
  text-decoration: none;
}

.team-image-small {
  width: 150px;
  height: 150px;
  margin-left: 1rem;
  margin-right: 1rem;
}

.team-member-block-small {
  /* margin: 1rem; */
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 0.75rem;
}

.narrow-quote-block {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
}

.align-left {
  display: block;
  text-align: left;
  width: 40rem;
}

.executive-summary {
  .bottom {
    td {
      padding: 0.5rem;
    }
  }
}

.image-padding {
  padding-top: 0.2rem;
}

.accent-color {
  color: var(--link-active);
}
