.SummaryTable td {
  padding-top: 0rem;
  padding-bottom: 0rem;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}

.SummaryTable a {
  color: var(--accent-primary);
}

.SummaryTable a:hover {
  color: var(--text-highlight);
}

.PositionalSleepScore {
  color: var(--accent-primary);
  font-size: var(--large);
}
