.hero-stripe {
  width: 100%;
  /* min-height: 550px; */
  .header-primary {
    color: var(--text-primary);
    font-size: var(--xlarge);
    margin-bottom: 1.75rem;
    margin-top: 5rem;
  }
}

.stripe {
  width: 100%;
  min-height: 580px;
  color: var(--accent-primary);
}

.stripe.color-stripe {
  width: 100%;
  min-height: 580px;
  color: var(--text-primary);
}

.color-stripe {
  background-image: linear-gradient(
    120deg,
    var(--stipe-color-gradient-one),
    var(--stipe-color-gradient-two)
  );
}

.stripe-container {
  /* margin-left: var(--left-gutter);
    margin-right: var(--right-gutter); */
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 var(--right-gutter) 0 var(--left-gutter);
}

.stripe-container-gutter-free {
  display: flex;
  align-items: center;
  height: 100%;
}

.stripe-contents {
  width: 100%;
}

.stripe-header {
  font-size: var(--xlarge);
  margin-bottom: 1.75rem;
  margin-top: 5rem;
}

.stripe-copy {
  margin-left: var(--copy-indent);
  margin-bottom: 2rem;
  font-size: var(--large);
}

.side-sleeping-picture {
  margin: 2rem 0rem 0rem 0rem;
}

.sleeping-on-back-images-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.engineered-to-the-smallest-scale-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* justify-content: space-around; */
  align-items: flex-end;
}

@media only screen and (min-width: 692px) {
  .mobile-only {
    visibility: hidden;
  }
}

/* .hero-container{
display: grid;
grid-template-columns: auto auto;
justify-content: start;
} */

.hero-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background-color: #03060f;
  /* justify-content: stretch; */
}

.hero-details {
  /* background-color: #03060f; */
  /* background-color: rgb(44, 47, 53); */
  text-align: center;
  flex-grow: 1;
  min-width: 400px;
  margin-bottom: 1.75rem;
}

.hero-image {
  border: 0px;
  /* width: 60%; */
  min-width: 60%;
}

.hero-header {
  color: var(--text-primary);
  font-size: var(--xlarge);
  margin-top: 1.5rem;
  margin-bottom: 1.75rem;
  font-weight: var(--light);
}

.hero-copy {
  color: var(--text-primary);
  margin-bottom: 0.5rem;
  font-size: var(--large);
  font-weight: var(--thin);
}

.hero-button {
  border: none;
  padding: 0rem 0.8rem 0.3rem 0.8rem;
  background-color: var(--accent-lively);
  border-radius: 10px;
  margin: auto;
  font-weight: var(--light);
  font-size: var(--large);
  cursor: pointer;
  width: 12rem;
  color: var(--text-primary);
}

.hero-button:hover {
  background-color: var(--accent-primary);
}

.hero-button:active {
  background-color: var(--accent-lively);
}

.closing-button {
  border: none;
  padding: 0rem 0.8rem 0.3rem 0.8rem;
  background-color: var(--accent-lively);
  border-radius: 10px;
  margin: auto;
  font-weight: var(--light);
  font-size: var(--large);
  cursor: pointer;
  max-width: 38rem;
  color: var(--text-primary);
}

.closing-button:hover {
  background-color: var(--accent-primary);
}

.closing-button:active {
  background-color: var(--accent-lively);
}

.positional-therapy-opens {
  margin-top: 3rem;
}

.max-benefit-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  /* margin: 3rem 0rem; */
  width: 100%;
}

.device-upclose {
  margin: 0rem 3rem;
}

.device-caption {
  margin-left: 4rem;
}

.lady-sleeping-device {
  margin: 0;
}

.graph {
  margin: 0;
  max-width: 60rem;
}
